@import '@/assets/scss/variables.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/mixin.scss';

.card {
  @include info(0px 0px 0px 0px);
  @include mediumText(18px, 25px);
  font-size: 18px;
  width: 100%;

  @include respond-to(720px) {
    border-radius: 12px;
  }

  @include respond-to(720px) {
    @include mediumText(12px, 14px);
    min-width: 48px;
    min-height: 32px;
  }
}
