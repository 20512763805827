@import '@/assets/scss/mixin.scss';

.block {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  @include respond-to(720px) {
    flex-direction: column;
    width: fit-content;
    height: fit-content;
  }
}

.block_video {
  position: relative;
  overflow: hidden;
  width: 285px;
  height: 666px;
}

.reverse {
  @extend .block;
  flex-direction: row-reverse;

  @include respond-to(820px) {
    width: calc(100% - 20px);
  }

  @include respond-to(720px) {
    flex-direction: column;
  }
}

.parallax {
  width: 184px;
  height: 374px;
}

.playear {
  @include videoPlayer;
}
