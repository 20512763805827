@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  background-color: #fefcfe;
  flex-grow: 2;

  @include respond-to(980px) {
    max-width: 420px;
  }

  @include respond-to(720px) {
    max-width: 100%;
  }

  @include respond-to(720px) {
    background-color: $default-text-white-color;
    margin: 0 auto;
  }
}

.content_title {
  font-weight: 600;
  font-size: 60px;
  color: $default-text-black-color;
  margin-bottom: 16px;
  margin-top: 0;
  @include mediumText(60px, 85px);

  @include respond-to(720px) {
    @include mediumText(24px, 34px);
    margin-top: 20px;
  }
}

.describe {
  color: $default-text-black-color;
  font-weight: 400;
  margin: 0;
  border-bottom: 2px solid #f5f6f7;
  padding-bottom: 40px;
  padding-right: 10px;
  @include lightText(18px, 25px);

  @include respond-to(720px) {
    @include lightText(14px, 17px);
    border: none;
    padding-bottom: 0;
  }
}

.button {
  @include defaultStyleButton(24px, 40px, 24px, 40px);
  max-width: 266px;
  @include lightText(14px, 12px);

  @include respond-to(980px) {
    max-width: 100%;
    @include defaultStyleButton(16px, 40px, 16px, 40px);
  }

  @include respond-to(720px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

.content_cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 210px;

  @include respond-to(720px) {
    height: 135px;
  }
}

.qr {
  @include info(30px 40px 30px 40px);
  margin-right: 20px;

  @include respond-to(980px) {
    margin-right: 8px;
  }

  @include respond-to(720px) {
    @include info(18px 20px 16px 20px);
    border-radius: 12px;
    max-height: 30px;
  }
}
